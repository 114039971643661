import React, { useState, useMemo, useContext } from "react"
import { graphql } from "gatsby"

import { flatListToHierarchical } from "../../../../helpers"
import Layout from "../../../../components/layout"
import Wrapper from "../../../../components/reusable/Wrapper"
import usePagination from "../../../../components/reusable/Pagination/usePagination"
import { Pagination } from "../index"
import MediaLibrary from "../../../../components/image-library/MediaLibrary"
import MediaCategories from "../../../../components/image-library/MediaCategories"
import SearchBox from "../../../../components/image-library/SearchBox"
import { ImageLibraryContext } from "../../../../components/image-library/ImageLibraryContext"
import { ImageLibraryTopBar } from "../"

import { IoMdSearch } from "react-icons/io"

const ImageLibrary = ({ data, params, path }) => {
  const { allWpMabMediaItem, allWpMediaCategory, allWpFileType } = data
  const mediaCategories = allWpMediaCategory.edges.map(item => item.node)
  const fileTypes = allWpFileType.edges.map(item => item.node)
  const media = allWpMabMediaItem.edges.map(item => item.node)

  // remove ":number" from path
  const cleanPath = path.split(":")[0]

  const libraryRef = React.createRef()

  const imageLibraryData = useContext(ImageLibraryContext)
  const { activeCategoryId, setActiveCategoryId, activeFiletypeId, setActiveFiletypeId, searchQuery, setSearchQuery } = imageLibraryData;

  // filter activeItems by activeCategoryId
  const filteredItemsByCategory = media.filter(item => {
    if (activeCategoryId === "") {
      return true
    } else {
      return item.mediaCategories.nodes.find(
        category =>
          category.id === activeCategoryId ||
          category.parentId === activeCategoryId
      )
    }
  })
  // filter filteredItemsByCategory by activeFiletypeId
  const filteredItemsByFileType = filteredItemsByCategory.filter(item => {
    if (activeFiletypeId === "") {
      return true
    } else {
      return item.fileTypes.nodes.find(
        fileType => fileType.id === activeFiletypeId
      )
    }
  })

  // filter filteredItemsByFileType by searchQuery
  const filteredItemsBySearch = filteredItemsByFileType.filter(item => {
    if (searchQuery === "" || searchQuery === "") {
      return true
    } else {
      return item.title.toLowerCase().includes(searchQuery.toLowerCase())
    }
  })

  const [paginationPages] = usePagination({
    items: filteredItemsBySearch,
    show: 9,
  })

  const pagesOfItems = useMemo(
    () => [...Array(Math.ceil(filteredItemsBySearch.length / 9)).keys()].length,
    [filteredItemsBySearch]
  )

  const activePage = params?.number && params.number > 0 ? parseInt(params.number) : 1

  const activeItems = useMemo(
    () => filteredItemsBySearch.slice(activePage * 9 - 9, activePage * 9),
    [activePage, filteredItemsBySearch]
  )

  const currentQuery = () => {
    if (
      activeCategoryId === "" &&
      activeFiletypeId === "" &&
      searchQuery === ""
    ) {
      return "All"
    }
    if (
      activeCategoryId !== "" &&
      activeFiletypeId === "" &&
      searchQuery === ""
    ) {
      return mediaCategories.find(category => category.id === activeCategoryId)
        .name
    }
    if (
      activeCategoryId === "" &&
      activeFiletypeId !== "" &&
      searchQuery === ""
    ) {
      return fileTypes.find(fileType => fileType.id === activeFiletypeId).name
    }
    if (
      activeCategoryId !== "" &&
      activeFiletypeId !== "" &&
      searchQuery === ""
    ) {
      return `${
        mediaCategories.find(category => category.id === activeCategoryId).name
      } - ${fileTypes.find(fileType => fileType.id === activeFiletypeId).name}`
    }
    if (
      activeCategoryId === "" &&
      activeFiletypeId === "" &&
      searchQuery !== ""
    ) {
      return `Search: ${searchQuery}`
    }
    if (
      activeCategoryId !== "" &&
      activeFiletypeId === "" &&
      searchQuery !== ""
    ) {
      return `${
        mediaCategories.find(category => category.id === activeCategoryId).name
      } - Search: ${searchQuery}`
    }
    if (
      activeCategoryId === "" &&
      activeFiletypeId !== "" &&
      searchQuery !== ""
    ) {
      return `${
        fileTypes.find(fileType => fileType.id === activeFiletypeId).name
      } - Search: ${searchQuery}`
    }
    if (
      activeCategoryId !== "" &&
      activeFiletypeId !== "" &&
      searchQuery !== ""
    ) {
      return `${
        mediaCategories.find(category => category.id === activeCategoryId).name
      } - ${
        fileTypes.find(fileType => fileType.id === activeFiletypeId).name
      } - Search: ${searchQuery}`
    }
  }

  return (
    <Layout
      meta={{
        metaDescription:
          "Welcome to the Mitchells & Butlers media library, where you can find & download our latest content.",
        metaTitle: "Media Library | Mitchells & Butlers",
        ogDescription:
          "Access the Mitchells & Butlers media library to find & download our latest content.",
        ogTitle: "Media Library | Mitchells & Butlers",
        twitterDescription:
          "Access the Mitchells & Butlers media library to find & download our latest content.",
        twitterTitle: "Media Library | Mitchells & Butlers",
      }}
      title={"Media Library | Mitchells & Butlers"}
      canonical={
        params?.number ? `/media/image-library/page/${params.number}` : null
      }
      next={
        params?.number && params.number <= pagesOfItems
          ? `/media/image-library/page/${Number(params.number) + 1}`
          : null
      }
      prev={
        params?.number && params.number > 1
          ? `/media/image-library/page/${Number(params.number) - 1}`
          : null
      }
    >
      <div className="image-library">
        <div className="page-title">
          {/* TODO: use blocks */}
          <Wrapper>
            <h1>Image Library</h1>
          </Wrapper>
        </div>
        <Wrapper>
          <div className="sidebar">
            <h4>Find an image</h4>
            <p>
              Our library is updated regularly and images are free to use,
              provided you credit Mitchells & Butlers upon use or publication.
            </p>
            <div className="search-box">
              <h5>Search Images</h5>
              <SearchBox />
              <i>
                <IoMdSearch />
              </i>
            </div>
            <MediaCategories imageLibraryData={imageLibraryData}
              mediaCategories={flatListToHierarchical(mediaCategories)}
            />
          </div>
          <section ref={libraryRef} className="library">
            <ImageLibraryTopBar
              filteredItemsBySearch={filteredItemsBySearch}
              currentQuery={currentQuery}
              fileTypes={fileTypes}
              setActiveFiletypeId={setActiveFiletypeId}
              activeFiletypeId={activeFiletypeId}
            />

            <MediaLibrary mediaItems={activeItems} fileTypes={fileTypes} />

            <Pagination
              defaultPage={activePage}
              activePage={activePage}
              paginationPages={paginationPages}
              scrollRef={libraryRef}
              items={filteredItemsBySearch}
              path={cleanPath}
              currentPage={activePage}
            />
          </section>
        </Wrapper>
      </div>
    </Layout>
  )
}

export default ImageLibrary

export const query = graphql`
  query {
    allWpMabMediaItem {
      edges {
        node {
          id
          title
          mediaDetails {
            thumbnailUrl
            assetUrls {
              url
              fileType
              dimensions
              fileSize
            }
          }
          fileTypes {
            nodes {
              id
              name
              slug
              databaseId
            }
          }
          mediaCategories {
            nodes {
              id
              slug
              name
              parentId
            }
          }
        }
      }
      totalCount
    }
    allWpMediaCategory {
      edges {
        node {
          id
          uri
          parentId
          name
          count
        }
      }
    }
    allWpFileType {
      edges {
        node {
          id
          name
          slug
          databaseId
        }
      }
    }
  }
`
